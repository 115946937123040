//Vanilla JS/jQuery

(function($) {
  var pageTitle = document.querySelector('.page-title');
  var header = document.querySelector('.page-header');
  var cont = pageTitle.parentElement;

  //Page title height/width adjust
  var elDims, contH, contW, fontSize;

  function getDims() {
    elH = pageTitle.getBoundingClientRect().height;
    elW = pageTitle.parentElement.scrollWidth;
    contH = $(header).height();
    contW = Math.round(cont.getBoundingClientRect().width);
    fontSize = parseInt(
      window.getComputedStyle(pageTitle, null).getPropertyValue('font-size')
    );
    //console.log(elH, contH, elW, contW, fontSize);
  }

  getDims();
  index = 0;
  if (elH > contH || elW > contW) {
    while (index < 70 && (elH > contH || elW > contW)) {
      pageTitle.style.fontSize = fontSize - 2 + 'px';
      getDims();
      index++;
    }
  } else {
    while (index < 70 && (elH <= contH && elW <= contW)) {
      pageTitle.style.fontSize = fontSize + 2 + 'px';
      getDims();
      if (elH > contH || elW > contW) {
        //Roll back final adjustment if it makes the content exceed the container
        pageTitle.style.fontSize = fontSize - 2 + 'px';
        index = 70;
      }
      index++;
    }
  }
  header.classList.add('initialized');
})(jQuery);

//Set height of masonry layout for audiences content
(function($) {
  var cont = document.querySelector('.wp-block-audiences');
  var elH = 0;
  var els = cont.querySelectorAll('figure');
  //Fit height to first two images
  for (var e = 0; e < 2; e++) {
    elH += els[e].offsetHeight;
  }
  cont.querySelector(':scope > .wp-block-group__inner-container').style.height =
    Math.ceil(elH) + 2 + 'px';
})(jQuery);

//Scroll animations
(function($) {
  var controller = new ScrollMagic.Controller();

  //Intro image after header
  new ScrollMagic.Scene({
    triggerElement: '.wp-block-homepage-image-after-intro img',
  })
    .setTween('.wp-block-homepage-image-after-intro img', 2, {
      filter: 'grayScale(0)',
    })
    //.addIndicators()
    .addTo(controller);

  //Audiences block images
  var audiencesImages = Array.from(
    document.querySelectorAll('.wp-block-audiences figure img')
  );
  if (window.innerWidth > 1200) {
    audiencesImages.forEach((el, e) => {
      const index = e == 1 ? 2 : e == 2 ? 1 : e;
      //const index = e;
      new ScrollMagic.Scene({
        triggerElement: el.parentElement,
        duration: 2000 - 500 * index,
        triggerHook: 1,
      })
        .setTween(el, 1, {
          transform: 'translateY(0)',
        })
        .addTo(controller);
    });
  } else if (window.innerWidth > 760) {
    audiencesImages.forEach((el, e) => {
      new ScrollMagic.Scene({
        triggerElement: el.parentElement,
        duration: 1100,
        triggerHook: 1,
        offset: (e - 1) * 2 * (-0.2 * window.innerHeight),
      })
        .setTween(el, 1, {
          top: '-150%',
        })
        .addTo(controller);
    });
  } else {
    audiencesImages.forEach((el, e) => {
      new ScrollMagic.Scene({
        triggerElement: el.parentElement,
        duration: 1000,
        triggerHook: 1,
        offset: e * (-0.1 * window.innerHeight),
      })
        .setTween(el, 1, {
          top: '-200%',
        })
        .addTo(controller);
    });
  }

  // Bushwick (Serving Brooklyn and beyond) block sticky intro
  // Appear and stick

  var blockBushwick = document.querySelector('.wp-block-bushwick');
  var blockBushwickContent = blockBushwick.querySelector(
    '.wp-block-group__inner-container'
  );
  var blockBushwickSibling = blockBushwick.nextElementSibling;
  new ScrollMagic.Scene({
    triggerElement: blockBushwick,
    triggerHook: 0,
  })
    .setTween(
      TweenMax.set(blockBushwickContent, {
        className: 'wp-block-group__inner-container active sticky',
      })
    )
    .addTo(controller);

  //Unstick
  var blockBushwickUnstickTween = new ScrollMagic.Scene({
    triggerElement: blockBushwickSibling,
    triggerHook: 1,
  })
    .setTween(
      TweenMax.set(blockBushwickContent, {
        className: 'wp-block-group__inner-container',
      })
    )
    .on('start', function(e) {
      if (e.scrollDirection === 'FORWARD') {
        //Manually remove sticky since ScrollMagic wants to revert to no classes rather than sticky state classes
        setTimeout(() => {
          blockBushwickContent.classList.remove('sticky');
        }, 10);
      } else if (e.scrollDirection === 'REVERSE') {
        //Manually re-add sticky since ScrollMagic wants to revert to no classes rather than sticky state classes
        setTimeout(() => {
          blockBushwickContent.classList.add('sticky');
        }, 10);
      }
    })
    .addTo(controller);

  // NYC block BG
  if (window.innerWidth > window.innerHeight) {
    new ScrollMagic.Scene({
      triggerElement: '.wp-block-nyc',
      duration: 1000,
      triggerHook: 1,
    })
      .setTween('.wp-block-nyc figure img', 1, { top: '-100vh' })
      .addTo(controller);
  } else {
    new ScrollMagic.Scene({
      triggerElement: '.wp-block-nyc',
      duration: 1000,
      triggerHook: 1,
    })
      .setTween('.wp-block-nyc figure img', 1, { top: '-100vw' })
      .addTo(controller);
  }

  // NYC block header
  new ScrollMagic.Scene({
    triggerElement: '.wp-block-nyc',
    duration: 800,
    triggerHook: 1,
  })
    .setTween('.wp-block-nyc h2', 1, { bottom: '130px' })
    .addTo(controller);

  var blockNYC = document.querySelector('.wp-block-nyc');
  var blockNYCContent = blockNYC.querySelector(
    '.wp-block-group__inner-container'
  );
  var blockNYCSibling = blockNYC.nextElementSibling;
  var blockNYCHeader = blockNYC.querySelector('h2');
  var blockNYCHeaderGlyph = parseInt(
    window.getComputedStyle(blockNYCHeader).lineHeight
  );

  blockNYCHeader.innerHTML = blockNYCHeader.innerHTML.replace(
    'Welcom',
    "<span class='span-reference'>Welcom</span>"
  );
  var blockNYCHeaderReference = blockNYCHeader.querySelector('.span-reference');
  var blockNYCHeaderRect = blockNYCHeaderReference.getBoundingClientRect();

  blockNYCHeader.style.transformOrigin =
    100 * ((blockNYCHeaderRect.width + 4) / blockNYCHeader.clientWidth) +
    '% .66em';

  new ScrollMagic.Scene({
    triggerElement: '.wp-block-nyc',
    duration: 2000,
    triggerHook: 0,
  })
    .setTween('.wp-block-nyc h2', {
      scale: 100,
      ease: 'power3.in',
    })
    .addTo(controller);

  // new ScrollMagic.Scene({
  //   triggerElement: '.wp-block-nyc',
  //   duration: 2000,
  //   triggerHook: 0,
  // })
  //   .setTween('.wp-block-nyc', { opacity: '0', ease: 'power3.in' })
  //   .addTo(controller);

  // NYC block sticky intro
  // Appear and stick

  new ScrollMagic.Scene({
    triggerElement: blockNYC,
    triggerHook: 0,
  })
    .setTween(
      TweenMax.set(blockNYCContent, {
        className: 'wp-block-group__inner-container active sticky',
      })
    )
    .addTo(controller);

  //Unstick
  new ScrollMagic.Scene({
    triggerElement: blockNYCSibling,
    triggerHook: 1,
  })
    .on('start', function(e) {
      if (e.scrollDirection === 'FORWARD') {
        //Manually remove sticky since ScrollMagic wants to revert to no classes rather than sticky state classes
        setTimeout(() => {
          blockNYCContent.classList.remove('sticky');
        }, 10);
      } else if (e.scrollDirection === 'REVERSE') {
        //Manually re-add sticky since ScrollMagic wants to revert to no classes rather than sticky state classes
        setTimeout(() => {
          blockNYCContent.classList.add('sticky');
        }, 10);
      }
    })
    .addTo(controller);

  // Success Story/Lives Changed block sticky
  // Appear and stick

  var blockLC = document.querySelector('.successstory-list__outer');
  var blockLCSibling = blockLC.nextElementSibling;

  if (window.innerWidth > 1023) {
    blockLC.classList.add('transition');
    new ScrollMagic.Scene({
      triggerElement: blockLC.querySelector('.successstory-list__post'),
      triggerHook: 1,
    })
      .setTween(
        TweenMax.set(blockLC, {
          className: 'successstory-list__outer active sticky transition',
        })
      )
      .addTo(controller);

    //Unstick
    new ScrollMagic.Scene({
      triggerElement: blockLCSibling,
      triggerHook: 1,
    })
      .on('start', function(e) {
        if (e.scrollDirection === 'FORWARD') {
          //Manually remove sticky since ScrollMagic wants to revert to no classes rather than sticky state classes
          setTimeout(() => {
            blockLC.classList.remove('sticky');
          }, 10);
        } else if (e.scrollDirection === 'REVERSE') {
          //Manually re-add sticky since ScrollMagic wants to revert to no classes rather than sticky state classes
          setTimeout(() => {
            blockLC.classList.add('sticky');
          }, 10);
        }
      })
      .addTo(controller);
  } else {
    blockLC.style.height = 'auto';
    blockLC.style.minHeight = '100vh';
  }

  //Success Story Gallery Block content
  new ScrollMagic.Scene({
    triggerElement: '.successstory-list__wrapper',
    triggerHook: 0.75,
    duration: 500,
  })
    .setTween('.successstory-list__preheader + .row', {
      css: { transform: 'translateY(0)' },
    })
    .addTo(controller);

  // Potential block images
  Array.from(
    document.querySelectorAll('.wp-block-potential figure img')
  ).forEach((el, e) => {
    new ScrollMagic.Scene({
      triggerElement: el.parentElement,
      duration: 1500,
      triggerHook: 1,
    })
      .setTween(el, 2, { transform: 'translateY(0)' })
      .addTo(controller);
  });

  // Potential block header
  new ScrollMagic.Scene({
    triggerElement: '.wp-block-potential',
    duration: 1000,
    triggerHook: 1,
  })
    .setTween('.wp-block-potential h2', 1, { transform: 'translateY(0)' })
    .addTo(controller);

  //Join block BG
  new ScrollMagic.Scene({
    triggerElement: '.wp-block-join figure',
    triggerHook: 0.1,
  })
    .setTween('.wp-block-join figure img', 2, { filter: 'grayScale(0)' })
    //.addIndicators()
    .addTo(controller);

  Array.from(
    document.querySelectorAll('.wp-block-join .wp-block-join__group')
  ).forEach((el, e) => {
    new ScrollMagic.Scene({
      triggerElement: el,
      duration: 300,
      offset: -420 + 100 * (e + 1),
      triggerHook: 0.75,
    })
      .setTween(el, 2, { opacity: 1 })
      //.addIndicators()
      .addTo(controller);
  });

  //Join block BG
  // new ScrollMagic.Scene({
  //   triggerElement: '.wp-block-join',
  // })
  //   .setTween('.wp-block-join h2', 1, { opacity: 1 })
  //   //.addIndicators()
  //   .addTo(controller);
})(jQuery);
